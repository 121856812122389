import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import seva_card_image from "../assets/images/details_puja/seva_card_image.png";
import prasad from "../assets/images/details_puja/prasad.svg";
import record from "../assets/images/details_puja/record.svg";
import live from "../assets/images/details_puja/live.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import rightarrow from "../assets/images/icons/white-right-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import Log_in from "./Log_in";
import { useAppContext } from "./Auth";
function Sevacard_details({ sevacards, slug, pujaExpire }) {
  const [age, setAge] = useState("");
  const { isLoggedIn, setIsLoggedIn, open, setOpen, phone, setPhone } =
    useAppContext();

  const [selectedSevaTypes, setSelectedSevaTypes] = useState({});
  const navigate = useNavigate();
  const handleChange = (event, seva) => {
    const selectedSevaTypeId = event.target.value;
    setSelectedSevaTypes((prevState) => ({
      ...prevState,
      [seva.seva.id]: selectedSevaTypeId,
    }));
  };
  const options = { maximumFractionDigits: 2 };
  //readmore start
  const initialText =
    "dummy text to fill the sentences Lorem Epson is a dummy text to fill the sentences Lorem Epson is a dummy text to fill the se. dummy text to fill the sentences Lorem Epson is a dummy text to fill the dummy text to fill the sentences Lorem Epson is a dummy text to fill the sentences Lorem Epson is at to fil";

  const [isReadMore, setIsReadMore] = useState({});

  const toggleReadMore = (itemId) => {
    setIsReadMore((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId], // Toggle "Read more" state for the clicked item
    }));
  };

  //readmore end

  console.log(sevacards);
  return (
    <>
      {sevacards.map((seva, index) => (
        <div className="Welcome" key={index}>
          {seva.seva.image && (
            <img
              className="seva_cards_live_image"
              src={`${seva.seva.image.base_url}${seva.seva.image.image_path}`}
              alt=""
            />
          )}
          <div className="DurgaMaa">
            <Stack sx={{ height: "100%" }} justifyContent={"space-between"}>
              <Box>
                <Typography variant="h2">{seva.seva.name}</Typography>
                {seva.seva.seva_type && seva.seva.seva_type.length > 0 && (
  <>
    <div className="flex">
      <Typography variant="h4">
        <span>₹</span>
        {seva.seva.seva_type.length > 1
          ? selectedSevaTypes[seva.seva.id]
            ? seva.seva.seva_type.find(
                (type) => type.id === selectedSevaTypes[seva.seva.id]
              ).price
            : seva.seva.seva_type.find((type) => type.is_default)?.price || ""
          : Intl.NumberFormat("en-IN", options).format(
              Math.round(seva.seva.seva_type[0].price)
            )}
      </Typography>

      {seva.seva.seva_type.length > 1
        ? selectedSevaTypes[seva.seva.id]
          ? (() => {
              const strikeAmount = seva.seva.seva_type.find(
                (type) => type.id === selectedSevaTypes[seva.seva.id]
              ).strike_amount;
              return strikeAmount > 0 ? (
                <span style={{ textDecoration: "line-through", marginLeft: '8px' }}>
                  ₹{strikeAmount}
                </span>
              ) : null;
            })()
          : (() => {
              const defaultStrikeAmount = seva.seva.seva_type.find(
                (type) => type.is_default
              )?.strike_amount;
              return defaultStrikeAmount > 0 ? (
                <span style={{ textDecoration: "line-through", marginLeft: '8px' }}>
                  ₹{defaultStrikeAmount}
                </span>
              ) : null;
            })()
        : (() => {
            const strikeAmount = seva.seva.seva_type[0].strike_amount;
            return strikeAmount > 0 ? (
              <span style={{ textDecoration: "line-through", marginLeft: '8px' }}>
                ₹{Intl.NumberFormat("en-IN", options).format(
                  Math.round(strikeAmount)
                )}
              </span>
            ) : null;
          })()}
    </div>

    {seva.seva.seva_type.length > 1 && (
      <FormControl>
        <Select
          labelId={`demo-simple-select-label-${seva.seva.id}`}
          id={`demo-simple-select-${seva.seva.id}`}
          value={
            selectedSevaTypes[seva.seva.id] ||
            seva.seva.seva_type.find((type) => type.is_default)?.id || ""
          }
          onChange={(event) => handleChange(event, seva)}
        >
          {seva.seva.seva_type.map((sevaType, i) => (
            <MenuItem key={i} value={sevaType.id}>
              <span>{sevaType.name}</span>- <span>₹{sevaType.price}</span>{" "}
              {sevaType.strike_amount > 0 && (
                <span style={{ textDecoration: "line-through" }}>
                  ₹{sevaType.strike_amount}
                </span>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
  </>
)}

                <Typography variant="body1">
                  {/* {isReadMore
                ? seva.seva.description.slice(0, 300)
                : seva.seva.description} */}

                  {seva.seva.id && seva.seva.seva_type.length > 0
                    ? (() => {
                        let foundType = seva.seva.seva_type.find(
                          (type) => type.id === selectedSevaTypes[seva.seva.id]
                        );
                        selectedSevaTypes[seva.seva.id]
                          ? (foundType = seva.seva.seva_type.find(
                              (type) =>
                                type.id === selectedSevaTypes[seva.seva.id]
                            ))
                          : (foundType = seva.seva.seva_type.find(
                              (type) => type.is_default
                            ));
                        console.log(foundType);
                        return (
                          <>
                            {!isReadMore[foundType.id]
                              ? foundType.description.slice(0, 450)
                              : foundType.description}
                            {foundType.description.length > 450 && (
                              <span
                                onClick={() => toggleReadMore(foundType.id)}
                              >
                                {!isReadMore[foundType.id]
                                  ? "Read more...."
                                  : "Show less"}
                              </span>
                            )}
                          </>
                        );
                      })()
                    : seva.seva.seva_type.find((type) => type.is_default)
                        ?.description || ""}

                  {/* {seva.seva.description.length > 300 && (
                <span onClick={toggleReadMore}>
                  {isReadMore ? "Read more...." : "Show less"}
                </span>
              )} */}
                </Typography>

                <div className="live-content">
                  <div className="live-content-image">
                    <ul>
                      {seva.seva.live_available ? (
                        <li>
                          <Button
                            disableRipple
                            disableElevation
                            className="live_button"
                          >
                            <img src={live} alt="" />
                          </Button>
                        </li>
                      ) : null}
                      {seva.seva.recording_available ? (
                        <li>
                          <Button
                            disableRipple
                            disableElevation
                            className="record_button"
                          >
                            <img src={record} alt="" />

                            {seva.seva.recording_available ? "Recording" : ""}
                          </Button>
                        </li>
                      ) : null}
                      {seva.seva.prasad_available ? (
                        <li className="prasad">
                          <img src={prasad} alt="" />

                          {seva.seva.prasad_available ? "Prasad" : "No Prasad"}
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="welcome_book_now">
                    {pujaExpire === false && (
                      <Button
                        disableRipple
                        disableElevation
                        className="book_now"
                        onClick={() => {
                          if (!isLoggedIn) {
                            setOpen(true);
                          } else {
                            const sevaId = seva.seva.id;
                            let selectedTypeId = null;
                            if (seva.seva.seva_type.length === 1) {
                              selectedTypeId = seva.seva.seva_type[0].id;
                            } else {
                              selectedTypeId =
                                selectedSevaTypes[sevaId] ||
                                seva.seva.seva_type.find(
                                  (type) => type.is_default
                                )?.id;
                            }
                            navigate(
                              `/checkout/${slug}/${sevaId}/${selectedTypeId}`
                            );
                          }
                        }}
                      >
                        Book Now
                      </Button>
                    )}
                  </div>
                </div>
              </Box>
            </Stack>
          </div>
        </div>
      ))}
    </>
  );
}

export default Sevacard_details;



