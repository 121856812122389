import { Button, Container, Typography } from "@mui/material";
import "../styles/pages/home.css";
import Sevacards from "../components/Sevacards";
import Donation from "../components/Donation";
import Grid from "@mui/material/Grid";
import seva_bookings from "../assets/images/seva-bookings/step-for-booking-seva.png";
import Special_offer from "../components/Special_offer";
import Campaigner_card from "../components/Campaigner_card";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Testimonial from "../components/Testimonial";
import { Link } from "react-router-dom";
import React, { useRef, useState,useEffect } from "react";
// Import Swiper React components
import person_1 from "../assets/images/testimonial/person-1.png";
import person_3 from "../assets/images/testimonial/person-3.png";
import person_4 from "../assets/images/testimonial/person-4.png";
import testimonial_left_bg from "../assets/images/testimonial/testimonial_left_bg.png";
import testimonial_right_bg from "../assets/images/testimonial/testimonial_right_bg.png";
import Donationcards from "../components/Donationcards";
import sslb_bg from "../assets/images/donate-bg.png";
import noauthinstance from "../utils/api";
import pujaimg from "../assets/images/icons/puja.svg";
import puja_img from "../assets/images/icons/puja-w.png";
import puja2img from "../assets/images/icons/puja-1.svg";
import puja2_img from "../assets/images/icons/donation-w.png";
import bookingstep_1 from "../assets/images/seva-bookings/booking-step-01.svg";
import bookingstep_2 from "../assets/images/seva-bookings/booking-step-02.svg";
import bookingstep_3 from "../assets/images/seva-bookings/booking-step-03.svg";
import bookingstep_4 from "../assets/images/seva-bookings/booking-step-04.svg";
import seva_stipper from "../assets/images/dharma-parirakshana/bottom-strip.png";


// success popup  start

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SevaBookingSuccessfulImage from "../assets/images/check_out/mycart/seva-booking-successful-image.svg";
import dharma_parirakshana_bg_left_image from "../assets/images/dharma-parirakshana/dharma-bg-right.svg"
import dharma_parirakshana_bg_right_image from "../assets/images/dharma-parirakshana/dharma-bg-left.svg"
import whatsapp from '../assets/images/icons/whatsapp.png'
import GTM from "../utils/gtm";
import { useSearchParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// success popup  end

function Home() {

  const [displaySeva, setDisplaySeva] = useState(false);
  
  useEffect(() => {
    new GTM().trackHomePage();
  }, []);
  const scroll = useRef(null);
  const scroll2 = useRef(null)
  let [searchParams] = useSearchParams();
  const slug = searchParams.get('slug');
  const slug2 = searchParams.get('slug2');
 
 
 
 
  useEffect(() => {
    if(slug && scroll.current){
      setTimeout(() => {
      scroll.current.scrollIntoView({ behavior: 'smooth' });
    },250);
    }
    if (slug === "donation") setDisplaySeva(false)
    else if (slug === "puja") setDisplaySeva(true)
  }, [slug]);
  if (slug && scroll.current) {
    scroll.current.scrollIntoView({ behavior: 'smooth' });
  }
 
  useEffect(()=>{
    if(slug2 === "Campaigner"){
      setTimeout(() => {
        scroll2.current.scrollIntoView({ behavior: 'smooth' });
      },270);
    }
  },[slug2])
  
  return (
    <>
      <Header />
      <section className="app_header"></section>
      <section className="small_support_large">
        <div id="donations_pujas"></div>
        <div className="dharma_parirakshana_bg_left">
          <img src={dharma_parirakshana_bg_left_image}/>
        </div>
        <div className="dharma_parirakshana_bg_right">
          <img src={dharma_parirakshana_bg_right_image}/>
        </div>
       <Container className="donation_cards_container">
          <div className="small_support-main">
            <div className="main_smaill_box">
              <div className="main-box">
                <div className="bg">
                  <img src={sslb_bg} />
                </div>
                <Donation />
                <div ref={scroll}></div>
              </div>
            </div>
          </div>
          <div className="special_pujas_donations">
            <div className="special_pujas_donation_title">
              {/* <div className="darmashala-main">
                <div className="dharmashala_sm_left">
                  <img src={dharmashala_sm_left} />
                </div>
                <div className="dharmashala_sm_">
                  <img src={dharmashala_sm_left} />
                </div>
              </div> */}
              <h3>Dharma Parirakshana</h3>
              <p>
                {" "}
                Uphold the Glory of Sanatana Dharma: Support Temples, Veda
                Pathshalas, Goshalas, and Ashrams through your generous
                donations.
              </p>
            </div>
            {displaySeva ? <Sevacards /> : <Donationcards />}{" "}
          </div>
        </Container>
        <div className="seva_stipper">
          <img src={seva_stipper} />
        </div>
      </section>
      <section className="seva_bookings">
        <Container>
          <div className="step_booking_main_box">
            <Grid justifyContent={"center"} container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className="seva_booking_mini_content">
                  <div className="sev-booking-bg"></div>
                  <img className="seva_booking-image" src={bookingstep_1} />
                  <h3>Select a Cause</h3>
                  <p>Choose a Dharmic Act you want to support.</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className="seva_booking_mini_content">
                  <img className="seva_booking-image" src={bookingstep_2} />
                  <h3>Select a Product</h3>
                  <p>Choose what you want to donate.</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className="seva_booking_mini_content">
                  <img className="seva_booking-image" src={bookingstep_3} />
                  <h3>Proceed to Payment</h3>
                  <p>Make the payment for your donation.</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className="seva_booking_mini_content">
                  <img className="seva_booking-image" src={bookingstep_4} />
                  <h3>Receive the update</h3>
                  <p>
                    Collect your receipt and receive an update, acknowledging
                    your contribution.
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
      {/* <section className="testimonial">
        <div className="child_1">
          <img src={person_1} alt="person_1" />
        </div>
        <div className="child_2">
          <img src={person_1} alt="person_1" />
        </div>
        <div className="child_3">
          <img src={person_3} alt="person_3" />
        </div>
        <div className="child_4">
          <img src={person_4} alt="person_4" />
        </div>
        <div className="child_5">
          <img src={person_3} alt="person_4" />
        </div>
        <div className="">
          <Testimonial />
        </div>
      </section> */}
      <section className="our-special-offers app_new_our_special_offers">
        <Container>
          <div className="our-special-offers-bg">
            
            <Special_offer />
            <div ref={scroll2}></div>
          </div>
          
          <div className="campaigner-title">
          <div style={{height:'100px'}} ref={scroll2}></div>
            <h2>Start a Campaign</h2>
            <p>Join us, and serve the Sanatan Dharma</p>
          </div>
          <div className="main_campaigner_card">
            <Campaigner_card />
          </div>
        </Container>
      </section>
      <Footer />
      
      <a href="https://api.whatsapp.com/send?phone=919256585656" target="_blank"  class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
        <img alt="whatsapp" src={whatsapp}/>
      </a>
    </>
  );
}
export default Home;
